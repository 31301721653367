.sticky-wrapper {
  width: 100%;
  z-index: 2;
  background-color: var(--color-background);

  @media (--large) {
    display: inline;
    width: calc(var(--container-width) - 66%);
    position: -webkit-sticky;
    position: sticky;
    top: calc(var(--header-height) + var(--snackbar-height));
    align-self: flex-start;
  }

  /* & > * {
      margin-top: 3rem;
    } */
}
