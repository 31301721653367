.swiper__zoom__container {
  max-height: 100vh;
  max-width: 100%;
  overflow: hidden;
  & img {
    max-height: 100vh;
  }
}

.media-content {
  z-index: 910;
  background-color: var(--color-cart, var(--color-background));
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    outline: none;
  }
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 24px;
  cursor: pointer;
  z-index: 920;
  &:focus-visible {
    outline: none;
  }
  &:hover {
    color: var(--color-hover);
  }
}

.image__overlay {
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
  cursor: pointer;
  z-index: 900;
}

.image__overlay__text {
  background-color: var(--color-cart, var(--color-background));
  padding: 6px 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  min-width: 20%;
  gap: 10px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }

  & > p {
    font-size: var(--font-size-xs);
    padding: 0;
    margin: 0;
  }

  /* prevent select */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.gallery__container {
  --card-height: min(calc(3 / 2 * 100%), 40vh / 2 * 3, calc(90vw * 3 / 2));
  --card-width: calc(var(--card-height) / 3 * 2);

  -webkit-clip-path: inset(0);
  clip-path: inset(0);
  margin-bottom: 12px;
  position: relative;
  z-index: 1;

  /* -- code for aspect ratio 2 / 3 -- */
  width: auto;
  padding-bottom: var(--card-height);

  & swiper-slide {
    position: relative;
    width: var(--card-width);
    height: var(--card-height);
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (--large) {
    --card-height: calc(3 / 2 * 100%);
    padding-bottom: var(--card-height);
    position: sticky;
    bottom: 0;
    padding-bottom: 0;
  }
}

.gallery__container__inner {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  @media (--large) {
    position: relative;
  }
}

.image__gallery {
  display: none;

  @media (--large) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: var(--gap);

    & :nth-child(1),
    & :nth-child(2),
    & {
      grid-column: span 4;
    }

    & :nth-child(3) {
      grid-column: span 8;
    }

    & :nth-child(n + 4) {
      grid-column: span 2;
    }
  }
}

.gallery__swiper {
  height: 100%;
  @media (--large) {
    height: auto;
    display: none;
    & * {
      display: none;
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
  z-index: 1;

  @media (--large) {
    display: none;
  }
  &.in-modal {
    display: block;
  }
}

.media__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.image {
  z-index: 1;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover !important;

  &:hover {
    cursor: pointer;
  }
}

.image-swiper {
  z-index: 1;
}

.video {
  &:hover {
    cursor: pointer;
  }
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  & div {
    width: 100%;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  & video {
    width: 100%;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    object-fit: contain !important;
    @media (--large) {
      height: auto;
    }
  }
}
