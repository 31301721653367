.summary {
  position: sticky;
  bottom: 0;
  left: 0;
  font-size: 1.5em;
  font-weight: bold;
  color: #000;
  margin: 0;
  padding: 28px 0;
  text-align: center;
  width: 100%;
  background-color: var(--color-cart, var(--color-background));
}

.subtotals {
  border-top: 1px solid var(--color-primary-40);
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > span {
    display: flex;
    justify-content: space-between;

    & > p {
      font-size: var(--font-size-fixed-2xs);
      letter-spacing: 0.08em;
      font-weight: normal;
    }
  }
}

.cart__actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  & > button[disabled] {
    &.checkout-button {
      background-color: var(--color-primary-40);
      color: var(--color-black);
      cursor: not-allowed;
      border: none;
    }
  }
}

.shipping-text {
  font-size: var(--font-size-fixed-3xs);
  font-weight: normal;
  width: 100%;
  text-align: left;
  margin-top: 10px;
}

.grandtotal {
  display: flex;
  justify-content: space-between;
  font-size: 1.5em;
  font-weight: bold;
  color: #000;
  margin: 0;

  text-align: center;
  margin: 20px 0;
  width: 100%;

  & > p {
    &:first-child {
      font-weight: 600;
      letter-spacing: 0.08em;
    }
    font-weight: normal;
    font-size: var(--font-size-fixed-m);
  }
}

.checkout-button {
  /* general */
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  font-size: var(--font-size-fixed-3xs);
  /* custom */
  color: var(--color-white);
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  &:hover {
    background: var(--color-white);
    color: var(--color-primary);
  }
  border-radius: 20px;
  width: 50%;
}

.continue-button {
  width: 50%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  font-size: var(--font-size-fixed-3xs);
  /* custom */
  border: 1px solid var(--color-primary) !important;
  &:hover {
    background: var(--color-primary);
    color: var(--color-white);
  }
  border-radius: 20px;
}

.shipping_text__country {
  border-bottom: 1px solid black;
  margin-left: 0.4ch;
}
