.filters {
  position: fixed;
  inset: 0;
  bottom: auto;
  /* top: clamp(15px, 1vw, 30px); */
  top: calc(var(--header-height) + var(--snackbar-height));
  z-index: 2 !important;
  background: var(--color-background) !important;
  padding-block: 0.5rem;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  padding-inline: var(--page-inset);
  height: 30px;

  & > * {
    align-items: center;
  }

  & > *:first-child {
    justify-self: flex-start;
  }
  & > *:nth-child(2) {
    justify-self: center;
  }
  & > *:last-child {
    justify-self: end;
  }

  @media (--large) {
    padding-bottom: clamp(8px, 1vw, 16px);
    position: static;
    padding-inline: 0;
    grid-template-columns: repeat(3, 1fr);
    position: sticky !important;
    top: calc(4.2rem + var(--snackbar-height)) !important;
    /* hacky solution to avoid images from showing from below. */
    left: -2px;
    right: -2px;
    width: calc(100% +4px);
  }
}

.categories {
  display: none;

  @media (--large) {
    display: flex;
  }
  flex-direction: row;
  justify-content: center;
  gap: 1.125rem;
  padding-bottom: 0;
}

.filters-bar {
  --button-padding-v: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
  transform: translateX(var(--button-padding-v));

  @media (--mobile-only) {
    & > button {
      padding-left: 0;
    }
  }
  @media (--large) {
    display: flex;
    flex-direction: row;

    gap: 1.125rem;
    padding-right: 2rem;
  }

  & button {
    @extend %typo-b3;
    padding: 0;
  }
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: var(--font-size-xs);
  color: var(--color-primary-60);
  @media (--large) {
    padding-left: 1.5rem;
  }
}
.category {
  font-size: var(--font-size-xs);
}

.bar__text {
  font-family: var(--font-haas-grot-display) !important;
  font-size: var(--font-size-menu-item) !important;
  font-weight: 300 !important;
  letter-spacing: var(--body-ls) !important;
  text-align: center !important;
  white-space: nowrap;
}

.mobileCategories {
  position: sticky;
  top: calc(var(--header-height) + var(--snackbar-height) + 30px);
  display: flex;
  margin-block: 1rem;
  gap: 1rem;
  overflow: auto;
  padding-block: 0.5rem;
  margin-inline: -2px;
  padding-inline: 2px;
  background: var(--color-background);
  z-index: 100;

  @media (--large) {
    display: none;
  }
}
