.checkbox {
  display: flex;
  align-items: center;
  gap: var(--gap);
  cursor: pointer;
  position: relative;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &.inactive {
    opacity: 0.5;
  }
}

.error {
  font-size: 12px;
  margin-top: 4px;
  position: absolute;
  bottom: -30px;
}

.fauxInput {
  box-sizing: border-box;
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #292929;
  border-radius: 2px;
  &.checked {
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      background: #292929;
      border-radius: 2px;
      left: 2px;
      top: 2px;
    }
  }
}
