.input {
  box-sizing: content-box;
  --transition: 200ms;
  --input-font-size: var(--font-size-xs);
  --input-padding-v: 17px;
  --input-padding-h: 11px;
  --input-br: 100px;
  @media (--large) {
    --input-font-size: var(--font-size-2xs);
    --input-padding-v: 1.5em;
    --input-padding-h: 1em;
  }
  width: 100%;
  padding-inline: var(--input-padding-v);
  padding-block: var(--input-padding-h);
  font-size: var(--input-font-size);
  background: var(--color-primary-20);
  border-radius: var(--input-br);
  border: 0;
  outline: 0;

  &:focus {
    outline: 1px solid var(--color-primary);
  }

  &:hover {
    &::placeholder {
      opacity: 0.6;
    }
  }

  &.invalid {
    border: 2px solid var(--color-error);
  }
}
.label {
  display: flex;
  position: relative;
  &.disabled {
    background-color: var(--color-primary-50);
  }
  & > .message {
    position: absolute;
    display: none;
    bottom: -15px;
    font-size: 12px;
    margin-top: 5px;
    &.success {
      color: var(--color-success);
      display: inline;
    }
    &.error {
      color: var(--color-error);
      display: inline;
    }
  }
  & > .placeholder {
    color: var(--color-primary);
    pointer-events: none;
    font-weight: 500;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
    transition: all 0.3s ease;
    font-size: 16px;

    &.disabled {
      color: var(--color-primary-50);
    }

    &.smallPlaceholder {
      top: 14px;
      left: 25px;
      font-size: 12px;
      .required {
        position: absolute;
        right: -5px;
        color: var(--color-error);
      }
    }

    & > .required {
      position: absolute;
      right: -9px;
      color: var(--color-error);
    }
  }
}
.smallPlaceholder input {
  padding-top: 25px;
  padding-bottom: 15px;
}
