.product-actions {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  @media (--large) {
    gap: 12px;
    margin-bottom: clamp(10px, 0.732vw, 20px);
  }
}

.add-to-cart-button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.product-action {
  display: flex;
  justify-content: center;
  cursor: pointer;
  @media (--large) {
    width: 173px;
    padding: 15px;
    border-radius: 24px;
  }
}

.size-action {
  background-color: #ffffff;
  border: 1px solid #aaa197;
}

.cart-action {
  width: 100%;
  justify-content: center;
  background-color: var(--color-primary-50);
  color: var(--color-black);
  text-transform: uppercase;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  &.selected {
    background-color: var(--color-black);
    color: #ffffff;
  }
}

.denim {
  & > .cart-action {
    width: 100%;
    justify-content: center;

    color: var(--color-black);
    text-transform: uppercase;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

.image {
  object-fit: cover;
}
