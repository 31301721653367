.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6px;
  overflow: auto;
  row-gap: 40px;
}

.product-card-size {
  margin-top: 4px;
  @extend %typo-b2;
  letter-spacing: 0.48px;
  font-size: var(--font-size-2xs);
}

.cart-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100%;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 0.5rem;
}

.cart-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart-content[data-state="inactive"] {
  display: none;
  height: 0;
}

.size {
  font-size: var(--font-size-2xs);
}
