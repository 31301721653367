.editorial-wrapper {
  grid-column: span 4;

  @media (--large) {
    grid-column: span 4;
  }
}

.contentBlock {
  grid-column: span 4;
  /* grid-row: calc(var(--row) * 2 + (var(--row-span) - 1)) / span 1; */
  grid-row: calc(var(--row) + 1) / span var(--row-span);
  aspect-ratio: 4 / 5;
  display: block;

  @media (--large) {
    grid-column: var(--grid-column);
    aspect-ratio: unset;

    grid-row: var(--row) / span var(--row-span);
  }

  &[data-span-all] {
    aspect-ratio: 16 / 9;
  }
}

.isFirstColWithRowSpan {
  @media (--large) {
    width: auto;
    max-width: 100%;
    aspect-ratio: unset;
  }
}

.isOnFirstRow {
  grid-row: 1;

  @media (--large) {
    grid-row: var(--row) / span var(--row-span);
  }
}

.contentBlockWrap {
  width: 100%;
  height: 100%;
}

:global([data-grid-layout="six"]) .contentBlock {
  /*   @media (--medium) {
    display: none;
  } */
}

.video {
  width: 100%;
  height: 100%;
  max-width: 100%;

  object-fit: cover;
}
