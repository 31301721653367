.sizes__container {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  margin: 8px 0;
  margin-bottom: 24px;

  & > div {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;

    & > button {
      padding: 15px;
    }
  }

  & > p {
    font-size: var(--font-size-2xs);
  }

  @media (--large) {
    margin-top: 15px;
  }
}

.size {
  font-size: var(--font-size-2xs);
  padding: 0;

  &.selected {
    text-decoration: underline;
    text-underline-offset: 4px;
    color: var(--color-text);
  }

  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;
    color: var(--color-hover);
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    text-decoration: line-through;
    color: #b7aea3;
  }
}

.inactive {
  color: #b7aea3;
  cursor: not-allowed;
  text-decoration: line-through;
}

.few-left {
  color: #964000;
  font-size: var(--font-size-3xs);
}
