.modal {
  z-index: 920;
  height: fit-content;
  width: 90%;

  background-color: var(--color-background);
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 3rem;
  overflow: auto;
  padding-bottom: 4rem;

  @media (--large) {
    width: 50%;
  }
}

.modal-button-container {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.text-center {
  text-align: center;
}

.close {
  align-self: end;
}

.input {
  --transition: 200ms;
  --input-font-size: var(--font-size-xs);
  --input-padding-v: 17px;
  --input-padding-h: 11px;
  --input-br: 100px;
  @media (--large) {
    --input-font-size: var(--font-size-2xs);
    --input-padding-v: 1.5em;
    --input-padding-h: 1em;
  }
  width: 100%;
  padding-inline: var(--input-padding-v);
  padding-block: var(--input-padding-h);
  font-size: var(--input-font-size);
  background: var(--color-primary-20);
  border-radius: var(--input-br);
  border: 0;

  &:focus {
    border: 1px solid var(--color-black);
  }
  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    color: var(--color-primary-70);
    font-size: var(--input-font-size);
  }

  &:hover {
    &::placeholder {
      opacity: 0.6;
    }
  }
}
