.title {
  @extend %typo-t3;
  font-size: var(--font-size-fixed-22);
  grid-column: span 2;
  padding-bottom: 8px;
  border-bottom: 1px solid #000;
}

.summaryTitle {
  @extend %typo-t3;
  font-size: var(--font-size-fixed-22);
  grid-column: span 2;
  margin-bottom: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid #000;
}

.skeleton {
  border: unset;
  width: 100%;
  height: 395px;
}

.container {
  min-height: 100vh;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: var(--page-inset);
  gap: 30px;

  margin-bottom: 64px;

  .form {
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    position: sticky;
    top: 120px;
    align-items: flex-start;

    & input {
      --input-padding-h: 20px;
      --input-font-size: 16px;
      box-sizing: border-box;
    }
    label {
      width: 100%;
    }
  }

  & > .cart {
    margin-top: 20px;
    grid-column: span 4;
    position: sticky;
    z-index: 3;
    height: 100%;
    position: relative;
    & > div {
      &:nth-child(2) {
        height: max-content;
      }
      &:nth-child(3) {
        position: sticky !important;
        inset: auto !important;
        top: 80% !important;
      }
    }
  }

  @media (--large) {
    margin-bottom: 200px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 36px;
    .form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      label {
        width: auto;
      }
    }
  }
}

.stickyContainer {
  order: 1;

  @media (--large) {
    order: -1;
    grid-column: 2 / span 6;
  }
}

.button {
  padding: 12px 75px;

  border-radius: 2px;
  display: flex;
  width: max-content;
}
.addAdress2Container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  .addAdress2 {
    display: flex;
    padding: 12px 16px;
  }
}

.address1 {
  grid-column: span 2;
  position: relative;
}

.ingridContainer {
  position: sticky;
  top: 120px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.buttonContainer {
  grid-column: span 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;

  & button {
    border: 1px solid #000;
  }
}

.checkboxes {
  display: flex;
  gap: 20px;

  justify-content: space-between;
  grid-column: span 2;
  align-items: center;
}

@media (--mobile-only) {
  .container {
    margin-bottom: 144px;
    margin-top: 32px;
    .stickyContainer {
      grid-column: span 4;
      grid-row: 2;

      & > .form {
        display: flex;
        flex-direction: column;
        grid-gap: 30px;
        position: relative;
        top: auto;
      }
    }
    & > .cart {
      grid-column: span 4;
      grid-row: 1;
      padding-bottom: 0px;
    }
  }
  .checkboxes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .title {
    top: auto;
    position: relative;
  }
}

.decorated_input_label {
  font-family: var(--heading-family);
  letter-spacing: 0.05em;
}

.giftbox__wrapper {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.giftbox__image {
  position: relative;
  width: 33%;
  aspect-ratio: 2 / 3;
  flex-shrink: 0;
}

.giftbox__content {
  width: 100%;
  & .giftbox__desc {
    margin-bottom: 30px;
    font-size: 16px;
  }
}
.giftbox__item {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.giftbox__checkbox {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  min-width: 100px;

  & > * {
    gap: 0 !important;
  }
}

.loader {
  --size: 12px;
  --stroke-width: calc(var(--size) / 6);
  --color: currentColor;
  --animation-timing-function: linear;
  --animation-duration: 1s;
  width: var(--size);
  height: var(--size);
  border-width: var(--stroke-width);
  border-style: solid;
  border-color: var(--color) var(--color) var(--color) transparent;
  border-radius: 50%;
  transform: rotate(0deg);
  animation: var(--animation-timing-function) var(--animation-duration) infinite
    circle-spin-1-animation;
}
@keyframes circle-spin-1-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
