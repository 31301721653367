.section {
  display: flex;
  flex-direction: column;
  padding-top: 128px;
  max-width: 600px;
  margin-inline: auto;
  /* gap: 2.5rem; */

  & h1 {
    @extend %typo-h4;
    text-align: center;
    margin-bottom: 2.5rem;
  }

  & h2 {
    @extend %typo-h5;
    text-align: center;
    margin-bottom: 2rem;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin-bottom: 1rem;
}

.options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 64px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-primary-10);
  margin-bottom: 32px;

  &.padding-top {
    margin-top: 64px;
  }
}

.agreements {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  padding-inline: 8px;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: var(--font-size-xs) !important;
  color: var(--color-primary-80);
}

.inline-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap);

  & span {
    font-size: var(--font-size-xs);
    color: var(--color-text);
  }
  & a {
    font-size: var(--font-size--fixed-xs);
    color: var(--color-text);
    font-weight: 500;
    transition: opacity 200ms ease;
    transition-delay: 75ms;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--color-black);
      transform: scaleX(1);
      transform-origin: center;
      transition: transform 200ms ease;
    }

    &:hover {
      opacity: 0.6;
      &::after {
        transform: scaleX(0);
      }
    }
  }
}

.error {
  padding-top: 1rem;
  color: var(--color-error);
}

.success {
  padding-top: 1rem;
  color: var(--color-success);
  text-align: center;
}
