.grid {
  min-height: 50vh;
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  justify-content: center;

  flex-direction: column;
  @media (--large) {
    max-width: 500px;
  }
}

.orderInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.orderSummary {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.orderItems {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.details {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.orderDetails {
  display: flex;
  flex-direction: column;
  gap: 5px;
  & > * {
    text-align: right;
  }
}

.shippingDetails {
  display: flex;
  flex-direction: column;
  gap: 5px;
  & > * {
    text-align: left;
  }
}
