.add-voucher {
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-left: auto;

  & > p,
  span {
    font-size: var(--font-size-fixed-2xs);
    font-weight: normal;
    text-transform: uppercase;
    padding: 0;
  }

  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
  margin-bottom: 8px;
}

.form {
  width: 100%;
  min-height: 50px;
  margin-top: 1rem;
  @media (--large) {
    margin-top: 0;
  }
  margin-bottom: 8px;

  & input {
    --input-padding-h: 20px;
    --input-font-size: 16px;
  }
}

.container {
}

.activeVoucher {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  gap: 4px;
}

.subContainer {
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > p {
    font-size: var(--font-size-fixed-2xs);
  }
}

.button {
  padding: 0;
  font-size: var(--font-size-fixed-2xs);
  font-weight: normal;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
}

.buttons {
  position: relative;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-top: 4px;
  @media (--large) {
  }
  & > button {
    padding: 0;
  }
}

.inputError input {
  border: 2px solid var(--color-error);
}

.error {
  position: absolute;
  left: 0;
  font-weight: 500;
  color: var(--color-error);
  font-size: 12px;
}
