.product-variants-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6px;

  @media (--large) {
    gap: 10px;
    margin-top: 75px;
    margin-bottom: 44px;
  }
  & > p {
    @extend %typo-b3;
    @media (--mobile-only) {
      font-size: 0.75rem;
      text-transform: uppercase;
    }
  }
}
.product-variants {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > a {
    padding: 8px 15px;
  }
}

.product-variant {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 1px solid var(--color-primary);
  text-align: center;
  position: relative;

  &:hover {
    cursor: pointer;
    border: 1px solid var(--color-hover);
  }

  &.selected {
    &:before {
      content: "";
      position: absolute;
      width: 14px;
      bottom: -5px;
      border-bottom: 1px solid #7e7b75;
      left: 0;
    }
  }
}
