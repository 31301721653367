/* Typography tokens */
%typo {
  &-h {
    &1 {
      font-family: var(--heading-family);
      font-size: var(--font-size-3xl);
      line-height: 1;
      letter-spacing: -0.01em;
      font-weight: 100;
    }

    &2 {
      font-family: var(--heading-family);
      font-size: var(--font-size-2xl);
      line-height: calc(64 / 58);
      font-weight: 100;
      letter-spacing: var(--heading-ls);
      text-transform: var(--heading-transform);
    }

    &3 {
      font-family: var(--heading-family);
      font-size: var(--font-size-xl);
      line-height: calc(28 / 25);
      font-weight: 100;
      letter-spacing: var(--heading-ls);
      text-transform: var(--heading-transform);
    }

    &4 {
      font-family: var(--heading-family);
      font-size: var(--font-size-l);
      line-height: 1.12;
      font-weight: var(--typo-weight);
      letter-spacing: 0.01em;
    }

    &5 {
      font-family: var(--heading-family);
      font-size: var(--font-size-m);
      line-height: 1.2;
      font-weight: var(--typo-weight);
      letter-spacing: 0%;
    }
  }

  &-t {
    &1 {
      font-family: var(--heading-family);
      font-size: var(--font-size-38);
      line-height: calc(42 / 38);
      font-weight: var(--typo-weight);
      letter-spacing: var(--title-ls);
    }

    &2 {
      font-family: var(--heading-family);
      font-size: var(--font-size-28);
      line-height: calc(32 / 28);
      font-weight: 300;
      letter-spacing: var(--title-ls);
    }

    &3 {
      font-family: var(--heading-family);

      font-size: var(--font-size-22);
      line-height: 1.15;
      font-weight: 300;
      letter-spacing: var(--title-ls);
    }

    &4 {
      font-family: var(--heading-family);
      font-size: var(--font-size-s);
      line-height: 1.125;
      font-weight: 300;
      letter-spacing: var(--title-ls);
    }
  }

  &-b {
    &1 {
      font-size: var(--font-size-m);
      letter-spacing: var(--body-ls);
      font-weight: 500;
      line-height: var(--body-lh);
      text-transform: var(--body-transform);
    }
    &2 {
      font-size: var(--font-size-18);
      letter-spacing: var(--body-ls);
      font-weight: 500;
      line-height: var(--body-lh);
      text-transform: var(--body-transform);
    }
    &3 {
      font-size: var(--font-size-2xs);
      letter-spacing: var(--body-ls);
      font-weight: 500;
      line-height: var(--body-lh);
      text-transform: var(--body-transform);
    }
    &4 {
      font-size: var(--font-size-4xs);
      letter-spacing: var(--body-ls);
      font-weight: 500;
      line-height: var(--body-lh);
      text-transform: var(--body-transform);
    }
  }
  &-menu {
    &-link {
      &1 {
        font-size: var(--font-size-menu-item);
        letter-spacing: var(--body-ls-fixed);
        text-transform: uppercase;
        font-family: var(--font-haas-grot-display);
        font-weight: 300;
      }

      &2 {
        font-size: 0.6875rem;
        letter-spacing: var(--body-ls);
        font-family: var(--font-haas-grot-display);
        font-weight: 300;
      }
    }

    &-tag {
      &1 {
        font-size: 0.75rem !important;
        letter-spacing: var(--tag-ls);
        font-family: var(--font-century-gothic);
        font-weight: 300;
      }
    }
  }
}
