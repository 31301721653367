.storyblok__header {
  /* min-height: 30vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 4rem;
  @media (--large) {
    padding-block: 6rem;
  }
}
