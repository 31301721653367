.modal__title {
  @extend %typo-menu-link1;
  text-transform: capitalize;
}

.modal__t2 {
  font-family: var(--font-century-gothic) !important;
  font-size: var(--font-size-fixed-m) !important;
  text-transform: capitalize;
}

.content-modal {
  z-index: 920;
  width: min(100%, 32.5rem);
  background-color: var(--color-background);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  padding: 20px;
  overflow: auto;
}

.meassure-table {
  width: 100%;
  border-collapse: collapse;

  & > tbody > tr {
    & > td {
      @extend %typo-b3;
      padding: 8px 0;
    }
  }
  & > thead {
    & > th {
      text-align: left;
      padding: 10px 0;
      @extend %typo-h5;
    }
  }
}

.sub-title {
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.title {
  @extend %typo-h5;
}

.description {
  padding-top: 2rem;
  font-family: var(--font-century-gothic);
  font-weight: 400;
  justify-content: space-between;
  letter-spacing: 0.08em;
}

.tabs-trigger[data-state="active"] {
  color: var(--color-text);
  border-bottom: 1px solid var(--color-text);
}

.tabs-list {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tabs-content {
  margin-top: 32px;
}

.store-opening-hours {
  white-space: pre-line;
}

.trigger {
  margin-top: 1rem;
  display: flex;
  font-weight: 700;
  text-decoration: underline;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  &:hover {
    text-decoration: none;
  }

  @media (--large) {
    margin-bottom: 50px;
  }
}

.warehouses {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.warehouse-name {
  font-weight: 100;
  font-style: italic;
  text-align: left;
}

.warehouse-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > p {
    /* font-family: var(--font-century-gothic); */
    font-weight: 400;
    justify-content: space-between;
    letter-spacing: 0.08em;
  }
  padding: 2rem 0;
  border-bottom: 1px solid #bcb8af;

  &:first-child {
    border-top: 1px solid #bcb8af;
  }
}
