.cart-dialog {
  --modal-width: min(35rem, 100vw);
  --cart-padding: min(36px, 5%);
  width: var(--modal-width);
  z-index: 910;
  background-color: var(--color-cart, var(--color-background));

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding-inline: var(--cart-padding);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: none;
}

.cart-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart-content[data-state="inactive"] {
  display: none;
  height: 0;
}

.tabs-root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.close-button {
  cursor: pointer;
  font-size: var(--font-size-fixed-2xs);
}

.title {
  font-size: var(--font-size-fixed-2xs);
  letter-spacing: 0.08em;
  font-weight: 400;
}
.title[data-state="active"] {
  font-weight: 700;
  & > * {
    font-weight: 700;
  }
}

.cart-header {
  z-index: 910;

  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  position: sticky;
  background-color: var(--color-cart, var(--color-background));
  & > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.cart__text__desktop {
  @extend %typo-menu-link1;
  display: none;

  --svg-color: var(--color-black);

  @media (--large) {
    display: block;
  }
}

.cart__text__mobile {
  display: block;
  @media (--large) {
    display: none;
  }
}

.mobile__cart__indicator {
  /* Hide quantity number on mobile */
  display: none;

  /* display: grid;
  place-items: center;
  position: absolute;
  top: -3px;
  right: -8px;
  font-size: 8px;
  font-weight: 900;


  & > span {
    &::before {
      content: "( ";
    }
    &::after {
      content: " )";
    }
  } */

  @media (--large) {
    display: none;
  }
}

.cart-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 1rem;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 0.5rem;
}
